import React, { useState } from 'react';
import './styles.css'; // Import the CSS file

export const About = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div id="about" className="about-page">
      <div className="about-container">
        {/* Hero Section */}
        <section
          className="hero-area"
          style={{ backgroundImage: 'url(/img/about.png)' }} // Ensure correct image path
        >
          <h1 className="about-hero-title">About Tilil Technologies</h1>
        </section>

        {/* About Section */}
        <div className="about-container-2">
          <section className="about-content-section">
            <div className="row">
              <div className="col-lg-9">
                {/* Company Introduction */}
                <div className="about-intro">
                  <h2 className="title">Who we are</h2>
                  <span className="cate">Tilil Technologies</span>
                  <p className='P'>
                    Tilil Technologies is a leading, highly innovative software house, systems integrator, and technology provider, established to provide leading-edge intelligent technical solutions and consulting services to businesses, organizations, and government. We allow secure, efficient, and effective communication with various information resources and services, anytime and anywhere.
                  </p>
                  <span className="cate-values">Our Values</span>
                  <ul className="bullet-list">
                    <li>Openness</li>
                    <li>Integrity</li>
                    <li>Innovation</li>
                    <li>Agility</li>
                  </ul>
                </div>

                {/* FAQ Section */}
                <h4 className="faq-section-title">More About Us</h4>
                <div className="faq-wrapper">
                  {/* FAQ Item 1 */}
                  <div className={`faq-item ${openFaq === 0 ? 'active' : ''}`}>
                    <div className="faq-title" onClick={() => toggleFaq(0)}>
                      <h6 className="title">10+ years of experience</h6>
                      <span className="right-icon">{openFaq === 0 ? '▲' : '▼'}</span>
                    </div>
                    {openFaq === 0 && (
                      <div className="faq-content">
                        <p>
                          For more than 10 years, Tilil Technologies has forged strategic partnerships that have enabled us to deploy several customer-based digital solutions. We provide various digital solutions for banks, NGOs, and Government Institutions.
                        </p>
                      </div>
                    )}
                  </div>

                  {/* FAQ Item 2 */}
                  <div className={`faq-item ${openFaq === 1 ? 'active' : ''}`}>
                    <div className="faq-title" onClick={() => toggleFaq(1)}>
                      <h6 className="title">Scalable and Modular Solutions</h6>
                      <span className="right-icon">{openFaq === 1 ? '▲' : '▼'}</span>
                    </div>
                    {openFaq === 1 && (
                      <div className="faq-content">
                        <p>
                          We pride ourselves on providing scalable, modular, and interoperable solutions that work across devices.
                        </p>
                      </div>
                    )}
                  </div>

                  {/* FAQ Item 3 */}
                  <div className={`faq-item ${openFaq === 2 ? 'active' : ''}`}>
                    <div className="faq-title" onClick={() => toggleFaq(2)}>
                      <h6 className="title">Compliance</h6>
                      <span className="right-icon">{openFaq === 2 ? '▲' : '▼'}</span>
                    </div>
                    {openFaq === 2 && (
                      <div className="faq-content">
                        <p>
                          Our platform and solutions architecture is PCI/DSS compliant, offering the highest levels of security available for the financial industry, passing continuous web penetration testing. We are licensed by telecom operators in Kenya, Uganda, Tanzania, Mozambique, and partners across Africa.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
     {/* Sidebar Section */}
              <div className="col-lg-3">
                <aside className="sidebar">
                  <div className="widget widget-category">
                    <h5 className="title">Services</h5>
                    <ul>
                      <li><a href="https://tililtech.com/vas">VAS</a></li>
                      <li><a href="https://tililtech.com/iot">IoT</a></li>
                      <li><a href="https://tililtech.com/internet">Internet & Cloud Hosting</a></li>
                      <li><a href="https://tililtech.com/digital">Digital Solutions</a></li>
                      <li><a href="https://tililtech.com/login">Payments</a></li>
                      <li><a href="https://tililtech.com/consulting">Consulting</a></li>
                    </ul>
                  </div>
                </aside>

                {/* Download Section */}
                <div className="download-box">
                  <h5>Download File</h5>
                  <a href="https://tililtech.com/docs/api.pdf">API Document</a>
                  <i className="fa fa-cloud-download-alt" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
