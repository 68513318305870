import React from "react";

export const Features = (props) => {
  return (
    <div className="features-page">
      <h1 className="page-title">Why Choose Us</h1> {/* Title added */}
      <div className="features-container">
        {featuresData.map((feature, index) => (
          <div
            key={index}
            className="feature-card"
            style={{
              backgroundImage: `url(${feature.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="feature-title">{feature.title}</div>
            <div className="feature-description">{feature.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const featuresData = [
  {
    title: 'Global Reach & Local Presence',
    description: 'Tilil is a leading communication solution provider in Africa that aims to bridge communication gaps in a communication-driven world.',
    image: './img/2.png',
  },
  {
    title: 'Customer User Experience',
    description: 'Tilil Tech seeks to add real and appreciated value to mobile phone users based on various technologies and customer/client needs',
    image: './img/1.png',
  },
  {
    title: 'Fast & Flexible Solutions',
    description: 'We seek to harness and roll out fast and flexible cutting-edge solutions and services that create unison in the various IT aspects and fields.',
    image: './img/3.png',
  },
];

export default Features;
