import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => (event) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav
      id="menu"
      className={`tilili-navbar tilili-transparent-navbar ${
        scrolled ? "tilili-scrolled" : ""
      }`}
    >
      <div className="tilili-nav-container">
        <div className="tilili-navbar-header">
          <a href="#home" onClick={scrollToSection("header")} className="tilili-nav-logo"></a>
          <button
            type="button"
            className="tilili-navbar-toggle"
            onClick={toggleMenu}
            aria-label="Toggle navigation"
          >
            <span className="tilili-icon-bar"></span>
            <span className="tilili-icon-bar"></span>
            <span className="tilili-icon-bar"></span>
          </button>
        </div>

        <div
          className={`tilili-navbar-collapse ${isMenuOpen ? "tilili-show" : ""}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="tilili-navbar-nav">
            <li>
              <a href="#home" onClick={scrollToSection("header")}>
                Home
              </a>
            </li>
            <li>
              <a href="#about" onClick={scrollToSection("about")}>
                About Us
              </a>
            </li>
            <li className="dropdown">
              <a href="#services" onClick={scrollToSection("services")}>
                Services
              </a>
              <ul className="tilili-sub-menu">
                <li>
                  <a
                    href="https://tililtech.com/sms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bulk SMS
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/ussd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    USSD
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/whatsapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Whatsapp
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/ivr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IVR
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/airtime"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Airtime
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/digital"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Software|Digital Solutions
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/payments"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Payments
                  </a>
                </li>
                <li>
                  <a
                    href="https://tililtech.com/iot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IOT
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="https://tililtech.com/consulting">Consulting</a>
            </li>
            <li>
              <a href="https://tililtech.com/developer">Developers</a>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a href="https://tililtech.com/contact">Contact Us</a>
            </li>
            <li>
              <a
                href="https://portal.tililtech.com/login"
                className="tilili-login-btn"
              >
                Log In
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};