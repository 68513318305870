import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="service-container">
        <div className="section-title">
          <h2>~Our Services~</h2>
          <p>What We Do</p>
        </div>
        <div className="row service-row"> {/* Use Bootstrap's row class */}
          {props.data && props.data.length > 0
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 service-col"> {/* Four columns per row */}
                  <a href={d.link} className="service-link">
                    <div className="service-box">
                      <i className={d.icon}></i>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </a>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
