import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BlogDetail = () => {
  const { slug } = useParams(); // Capture the blog slug from the URL
  const [blog, setBlog] = useState(null);

  // Mock fetching blog data - you should replace this with your actual API call
  useEffect(() => {
    const fetchBlogData = async () => {
      // Example of fetching data based on slug
      const response = await fetch(`/api/blogs/${slug}`);
      const data = await response.json();
      setBlog(data);
    };

    fetchBlogData();
  }, [slug]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-detail">
      <h1>{blog.title}</h1>
      <img src={blog.photo_path} alt={blog.title} />
      <p>{blog.content}</p> {/* Assuming blog has a content field */}
    </div>
  );
};

export default BlogDetail;
