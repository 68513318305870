import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation"; // Navbar component
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import CounterSection from "./components/countersection";
import { Services } from "./components/services";
import BlogSection from "./components/blogsection"; // Blog is now its own page
import BlogDetail from "./components/blogdetail";
import USSDPage from "./components/USSDPage";
import { Worldmap } from "./components/worldmap";
import { Partners } from "./components/Partners";
import { Contact } from "./components/contact"; // Contact section at bottom
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      {/* Define the routes */}
      <Routes>
        {/* Home/Landing Page */}
        <Route
          path="/"
          element={
            <>
              <Navigation />
              <Header data={landingPageData.Header} />
              <Features data={landingPageData.Features} />
              <About data={landingPageData.About} />
              <CounterSection data={landingPageData.CounterSection} />
              <Services data={landingPageData.Services} />
              <Worldmap data={landingPageData.Worldmap} />
              <Partners data={landingPageData.Partners} />
              <Contact data={landingPageData.Contact} />
            </>
          }
        />

        {/* Blog Section - separate page */}
        <Route
          path="/blog"
          element={
            <>
              <Navigation />
              <BlogSection />
              <Contact data={landingPageData.Contact} />
            </>
          }
        />

        <Route
          path="/blog/:slug"
          element={<BlogDetail />} // Detail page for specific blog post
        />

        <Route
          path="/services"
          element={
            <>
              <Navigation />
              <Services data={landingPageData.Services} />
              <Contact data={landingPageData.Contact} />
            </>
          }
        />

        <Route
          path="/ussd"
          element={
            <>
              <Navigation />
              <USSDPage />
              <Contact data={landingPageData.Contact} />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
