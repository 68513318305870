import React from "react";
import { Link } from "react-router-dom";
import "./blogsection.css"; // Import the CSS file
import { Contact } from "./contact";

const BlogSection = ({
  latestBlogs = [],
  mostViewedBlogs = [],
  featuredBlog = {},
  topTags = [],
  monthlyArchives = [],
  blogCount = 0,
  pageIndex = 1,
}) => {
  // Sample blog posts to ensure that the blogs render correctly
  const sampleBlogs = [
    {
      blog_id: 1,
      blog_name_url: "sample-blog-1",
      photo_path: "/img/blog-1.jpg",
      blog_title: "Sample Blog 1",
      writer: "Admin",
      createdon: new Date().toISOString(),
      blog_views: 100,
      blog_summary: "This is a sample blog post summary.",
    },
    {
      blog_id: 2,
      blog_name_url: "sample-blog-2",
      photo_path: "/img/blog-2.jpg",
      blog_title: "Sample Blog 2",
      writer: "Editor",
      createdon: new Date().toISOString(),
      blog_views: 200,
      blog_summary: "Another example of a blog post summary.",
    },
    {
      blog_id: 3,
      blog_name_url: "sample-blog-3",
      photo_path: "/img/blog-3.jpg",
      blog_title: "Sample Blog 3",
      writer: "Author",
      createdon: new Date().toISOString(),
      blog_views: 300,
      blog_summary: "This is yet another blog post example.",
    },
  ];

  // Use sampleBlogs if latestBlogs is empty (for debugging)
  const blogsToRender = latestBlogs.length > 0 ? latestBlogs : sampleBlogs;

  const totalPages = Math.ceil(blogCount / 3);
  const pageCountLimit = 4;

  const renderPagination = () => {
    let pages = [];
    const startPage = pageIndex > pageCountLimit ? pageIndex : 1;
    const endPage = Math.min(startPage + pageCountLimit - 1, totalPages);

    if (pageIndex > 1) {
      pages.push(
        <li key="prev">
          <Link to={`/index/page/${pageIndex - 1}`}>{"<<"}</Link>
        </li>
      );
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <li key={page}>
          <Link
            className={pageIndex === page ? "active-page" : ""}
            to={`/index/page/${page}`}
          >
            {page}
          </Link>
        </li>
      );
    }

    if (pageIndex < totalPages) {
      pages.push(
        <li key="next">
          <Link to={`/index/page/${pageIndex + 1}`}>{">>"}</Link>
        </li>
      );
    }

    return <ul className="pagination-nav">{pages}</ul>;
  };

  return (
    <div className="blog-section-root">
      {/* Start Breadcrumb and Heading */}
      <section id="breadcrumb-header">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Blog
              </li>
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
            </ol>
          </nav>
          <h1 className="blog-title">Tilil Technologies Blog</h1>
        </div>
      </section>
      {/* End Breadcrumb and Heading */}

      {/* Start single page header */}
      <section id="single-page-header">
        <div className="banner-wrapper">
          <img
            src="/img/blog-banner1.png"
            alt="Banner"
            className="banner-image"
          />
        </div>
      </section>
      {/* End single page header */}

      <section className="blog-grid-section">
        <div className="blog-grid">
          {blogsToRender.map((blog) => (
            <article key={blog.blog_id} className="blog-card">
              <div className="blog-image">
                <Link to={`/blog/${blog.blog_name_url}`}>
                  <img
                    src={blog.photo_path || "/api/placeholder/400/320"}
                    alt={blog.blog_title}
                  />
                </Link>
              </div>

              <div className="blog-content">
                <h2 className="blog-title">
                  <Link to={`/blog/${blog.blog_name_url}`}>
                    {blog.blog_title}
                  </Link>
                </h2>

                <div className="blog-meta">
                  <a
                    href={`https://play.google.com/store/apps/details?id=ke.co.tunda&referrer=utm_source%3D${
                      blog.writer || "Tilil_Tech"
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                  <span className="author-name">
                    By {blog.writer || "Tilil Technologies"}
                  </span>
                </div>

                <p className="blog-summary">{blog.blog_summary}</p>

                <div className="blog-footer">
                  <span className="blog-date">
                    {new Date(blog.createdon).toLocaleDateString()}
                  </span>
                  <span className="blog-views">
                    <i className="fa fa-eye"></i>
                    {blog.blog_views}
                  </span>
                </div>

                <Link to={`/blog/${blog.blog_name_url}`} className="read-more">
                  Read More
                </Link>
              </div>
            </article>
          ))}
        </div>

        {/* Pagination */}
        {blogCount > 3 && (
          <div className="pagination-container">{renderPagination()}</div>
        )}
      </section>

      {/* End blog archive */}
    </div>
  );
};

export default BlogSection;
