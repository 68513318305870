import React from "react";
import "./USSDPage.css";

const USSDPage = () => {
  const scrollToForm = () => {
    const formSection = document.getElementById("form-section");
    formSection?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="ussd-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <div className="hero-content">
            <h1>Understanding What is USSD</h1>
            <nav className="breadcrumb">
              <a href="/">Home</a>
              <span className="chevron">›</span>
              <a href="/services">Services</a>
              <span className="chevron">›</span>
              <span>USSD</span>
            </nav>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="main-content">
        <div className="container">
          <div className="content-grid">
            <div className="content-image">
              <img src="/api/placeholder/600/400" alt="USSD Service" />
            </div>
            <div className="content-text">
              <h2>How does the USSD service work?</h2>
              <p>
                Unstructured Supplementary Service Data (USSD), sometimes
                referred to as "Quick Codes" or "Feature codes", is a
                communication technology used to send text to cellular
                telephones from a mobile network application or computer.
              </p>
              <div className="benefits">
                <h3>Benefits of USSD include:</h3>
                <ul>
                  <li>Does not require an internet connection or data</li>
                  <li>Works on feature phones and smartphones</li>
                  <li>Intuitive menu with real-time interactivity</li>
                </ul>
              </div>
              <div className="button-group">
                <button
                  className="btn primary"
                  onClick={() => (window.location.href = "/contact")}
                >
                  Contact Sales
                </button>
                <button className="btn secondary" onClick={scrollToForm}>
                  Get USSD Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How Does USSD Benefit Businesses Section */}
      <section className="benefit-businesses">
        <div className="container">
          <h2>How Does USSD Benefit Businesses?</h2>
          <div className="benefit-grid">
            <div className="benefit-details">
              <h3>Enterprise USSD Service</h3>
              <h4>USSD in Marketing</h4>
              <p>
                Since USSD does not require an internet connection to work, it
                offers superior reach to offer coupons and vouchers. Unlike
                internet-based services, USSD operates without the need for data
                connectivity, making it functional for a wide population.
              </p>
              <h4>USSD for Banking</h4>
              <p>
                Mobile banking is possible via USSD on every mobile phone that
                has a GSM cellular connection. USSD services can be accessed
                anytime, anywhere, ensuring that users have access to important
                information and services around the clock.
              </p>
              <h4>USSD for Customer Service</h4>
              <p>
                With USSD, users receive fast, accurate information and
                responses as long as they interact on the USSD menu actively. It
                also allows for customer feedback collection and order
                confirmation or tracking for online purchases.
              </p>
            </div>
            <div className="benefit-image">
              <img src="/path/to/image.png" alt="USSD Service" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Features Section --> */}
      <section className="ussd-features">
        <div className="container">
          <h2>USSD Business Use Case</h2>
          <p>
            USSD can be used for WAP browsing, prepaid callback service,
            mobile-money services, location-based content services, menu-based
            information services, and as part of configuring the phone on the
            network.
          </p>
          <div className="features-grid">
            <div className="ussd-feature-card">
              <i className="fa fa-lock fa-2x"></i>
              <h3>Secure Updates</h3>
              <p>
                USSD sessions enable secure transactions whether paying for a
                service or accessing banking services.
              </p>
            </div>
            <div className="ussd-feature-card">
              <i className="fa fa-phone fa-2x"></i>
              <h3>Call to Action</h3>
              <p>
                Use USSD in your marketing campaigns for maximum reach and to
                get high-quality leads.
              </p>
            </div>
            <div className="ussd-feature-card">
              <i className="fa fa-envelope fa-2x"></i>
              <h3>Customized Messaging</h3>
              <p>
                Personalize the USSD menu for self-help services and to provide
                feedback on queries.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section id="form-section" className="ussd-contact-section">
        <div className="container">
          <div className="ussd-form-card">
            <h2>Get a USSD Code for Your Business</h2>
            <form method="POST" action="/web/mail">
              <input type="hidden" name="type" value="ussd" />
              <div className="ussd-form-grid">
                <div className="ussd-form-group">
                  <input
                    type="text"
                    placeholder="First name"
                    name="name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Business email"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="form-grid">
                <div className="form-group">
                  <input
                    type="tel"
                    placeholder="Phone number"
                    name="phone"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Company name"
                    name="company"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <textarea placeholder="Message" name="message"></textarea>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  value="USSD Service Request"
                  readOnly
                />
              </div>
              <button type="submit" className="btn primary full-width">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default USSDPage;
