import React from 'react';
import './styles.css'; // Import the CSS

export const Worldmap = () => {
  return (
    <section className="country-coverage-section padding-top">
      <div className="world-container">
        <div className="world-section-header">
          <h2 className="title">Delivering Digital Experience all over the world</h2>
          <p className='P'>Don't be limited by Geographical barriers. Our Services extend to every corner of the planet.</p>
        </div>
        <div id="vmap">
          {/* Uncomment and add more positions as needed */}
          {/* <div className="position-1">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Alaska</h6>
              <p className="area">From: Alaska</p>
            </div>
          </div> */}
          {/* <div className="position-3">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Alberta</h6>
              <p className="area">From: Canada</p>
            </div>
          </div> */}
          {/* <div className="position-4">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Sao Paulo</h6>
              <p className="area">From: Brazil</p>
            </div>
          </div> */}
          <div className="position-5">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Tokyo</h6>
              <p className="area">From: Japan</p>
            </div>
          </div>
          <div className="position-6">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Berlin</h6>
              <p className="area">From: Germany</p>
            </div>
          </div>
          <div className="position-7">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Moscow</h6>
              <p className="area">From: Russia</p>
            </div>
          </div>
          <div className="position-8">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Beijing</h6>
              <p className="area">From: China</p>
            </div>
          </div>
          <div className="position-9">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Africa</h6>
              <p className="area">East Africa</p>
            </div>
          </div>
          <div className="position-10">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Africa</h6>
              <p className="area">Southern Africa</p>
            </div>
          </div>
          <div className="position-11">
            <span className="dot"></span>
            <div className="details">
              <h6 className="name">Lagos</h6>
              <p className="area">From: Nigeria</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

