// countersection.jsx
import React from 'react';
import 'animate.css';
import './styles.css'; // Import the CSS file

const CounterSection = () => {
  // Your component JSX code here
  return (
    <section className="counter-up-section">
      <div className="container">
        <div className="counter-wrapper">
          <div className="counter-item">
            <div className="counter-header">
              <i className="flaticon-call-center"></i>
              <h2 className="title">
                <span className="counter">50</span>+
              </h2>
            </div>
            <p>Support Countries</p>
          </div>
          <div className="counter-item">
            <div className="counter-header">
              <i className="fa fa-smile"></i>
              <h2 className="title">
                <span className="counter">100</span>k
              </h2>
            </div>
            <p>Happy Customers</p>
          </div>
          <div className="counter-item">
            <div className="counter-header">
              <i className="flaticon-project"></i>
              <h2 className="title">
                <span className="counter">312</span>
              </h2>
            </div>
            <p>Total Projects Done</p>
          </div>
          <div className="counter-item">
            <div className="counter-header">
              <i className="flaticon-collaboration"></i>
              <h2 className="title">
                <span className="counter">57</span>
              </h2>
            </div>
            <p>Employees</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
