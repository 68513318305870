import React, { useState, useEffect } from 'react';
import './styles.css';

export const Partners = () => {
  const sponsors = [
    { id: 1, image: "/img/safaricom.png", alt: "Safaricom" },
    { id: 2, image: "/img/airtel.png", alt: "Airtel" },
    { id: 3, image: "/img/telkom.png", alt: "Telkom" },
    { id: 4, image: "/img/mpesa.png", alt: "Mpesa" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % (sponsors.length - 2)
      );
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [sponsors.length]);

  return (
    <section className="partners-section">
      <div className="partners-container">
        <div className="partners-header">
          <h2 className="partners-title">Our Partners And Clients</h2>
        </div>
        
        <div className="partners-slider">
          <div 
            className="partners-track"
            style={{
              transform: `translateX(-${currentIndex * 33.333}%)`
            }}
          >
            {[...sponsors, ...sponsors].map((sponsor, index) => (
              <div
                key={`${sponsor.id}-${index}`}
                className="partner-item"
              >
                <div className="partner-card">
                  <img
                    src={sponsor.image}
                    alt={sponsor.alt}
                    className="partner-image"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
