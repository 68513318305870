import React, { useState } from "react";
import "./contact.css"; // Import the CSS file
import axios from "axios";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    company: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(
        "/tilil_laravel/tilil/public/send_email",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSuccessMessage(response.data.success);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        company: "",
      });
    } catch (error) {
      setErrorMessage("Failed to send message. Please try again.");
    }
  };
  return (
    <section className="contact-section">
      <div className="contact-container">
        <div className="contact-left">
          <h4>CONTACT US</h4>
          <h2>Reach out to us about your needs.</h2>
          <div className="contact-info">
            <div className="info-box">
              <i className="icon-location"></i>
              <div>
                <h5>Our Location</h5>
                <p>Raphta Heights, Westlands, Nairobi, Kenya</p>
              </div>
            </div>
            <div className="info-box">
              <i className="icon-email"></i>
              <div>
                <h5>How Can We Help?</h5>
                <p>
                  <i class="fa fa-envelope"></i> info@tililtech.com
                </p>
                <p>
                  <i class="fa fa-phone"></i> +254 792 777 888
                </p>
              </div>
            </div>
          </div>
          <div class="map-section2">
            <h5>Find Us on the Map</h5>
          </div>
          <div class="map-section">
            <div class="google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.848095245263!2d36.7812043758275!3d-1.2635898356035227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f176043ce69c1%3A0x6090d501f3e0c8d4!2sRhapta%20Heights!5e0!3m2!1sen!2ske!4v1729065360524!5m2!1sen!2ske"
                width="400"
                height="300"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div>
          <h4>Send us a Message</h4>
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="contact-right">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Full Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone*</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject*</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message*</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button className="send-btn" type="submit">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
